<template>
  <div class="home">
    <Header/>
    <About/>
    <Description/>
    <Awards/>
    <FormSection/>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import About from "@/components/About";
import Description from "@/components/Description";
import Awards from "@/components/Awards";
import FormSection from "@/components/FormSection";
import Footer from "@/components/Footer";

export default {
  name: 'Home',
  components: {
    About,
    Awards,
    Description,
    Header,
    FormSection,
    Footer
  },
  metaInfo: {
    title: 'Mobile Marketing Agency - WakeApp',
    meta: [{
      vmid: 'description',
      name: 'description',
      content:"The best mobile marketing performance agency in Europe. Our 10 years of expertise help us dive into projects to develop and implement comprehensive strategies, attracting new users!",
    }],
  }
}
</script>
