import Modal from "@/components/Modal";

export function showModal() {
    this.$modal.show(
        Modal,
        {},
        {
            name: 'Modal',
            //width: this.$screen.width > 590 ? 573 : 288,
            height: 'auto',
            adaptive: true,
            scrollable: true,
            clickToClose: true,
        },
    {}
    )
}