<template>
  <div
    class="MainMenu"
    :class="{
      'MainMenu--active': isMenuActive,
      'MainMenu--opened': isMenuOpen
    }"
  >
    <div class="Wrapper MainMenu-Wrapper">
      <button
          class="MainMenu-Button"
          :class="{'MainMenu-Button--opacity': isMenuOpen}"
          @click="isMenuOpen = !isMenuOpen"
          type="button"
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
      <NavBar
          class="MainMenu-NavBar"
          :isOpened="isMenuOpen"
          @open-menu="isMenuOpen = !isMenuOpen"
          v-click-outside="onClickOutside"
      />
      <Logo
        class="MainMenu-Logo"
        :class="{'MainMenu-Logo--ready': isLogoActive}"
      >
        <svg width="175" height="28" viewBox="0 0 175 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g fill="transparent" fill-rule="evenodd" stroke="currentColor" stroke-width=".5">
            <path d="M88.0523 8.29688H87.3782V27.5531H88.0523H91.0916H102.258L103.591 23.8359H91.0916V19.7836H98.187V16.0664H91.0916V12.0141H103.591V8.29688H91.0916H88.0523Z"/>
            <path d="M44.9618 22.8228L46.6604 19.1095H51.2574L48.1762 12.6311L41.077 27.5531H36.9637L46.1196 8.3083L46.1158 8.29688H50.2367L50.2329 8.3083L59.3926 27.5531H55.2755L53.0246 22.8228H44.9618Z"/>
            <path d="M115.566 22.8228L117.264 19.1095H121.861L118.78 12.6311L111.681 27.5531H107.568L116.723 8.3083L116.72 8.29688H120.844L120.84 8.3083L129.996 27.5531H125.879L123.632 22.8228H115.566Z"/>
            <path d="M77.7425 27.5531H82.3356L73.5721 16.8015L81.7377 8.63584H76.6646L68.122 17.1785V8.29688H64.4048V27.5531H68.122V22.2516L71.0393 19.3342L77.7425 27.5531Z"/>
            <path d="M144.793 8.30078H135.008V12.0256H144.762C145.236 12.01 145.708 12.0899 146.15 12.2604C146.592 12.4309 146.995 12.6886 147.336 13.0182C147.676 13.3477 147.947 13.7424 148.132 14.1788C148.317 14.6151 148.412 15.0841 148.412 15.5581C148.412 16.032 148.317 16.501 148.132 16.9374C147.947 17.3737 147.676 17.7684 147.336 18.0979C146.995 18.4275 146.592 18.6852 146.15 18.8557C145.708 19.0262 145.236 19.1061 144.762 19.0905H138.725V13.7052H135.008V27.5532H138.725V22.8039H144.819C146.738 22.8039 148.579 22.0415 149.936 20.6844C151.293 19.3274 152.056 17.4868 152.056 15.5676C152.056 13.6484 151.293 11.8078 149.936 10.4507C148.579 9.09365 146.738 8.33125 144.819 8.33125L144.793 8.30078Z"/>
            <path d="M174.237 15.5483C174.237 13.6533 173.494 11.8338 172.168 10.4807C170.841 9.12757 169.036 8.34891 167.142 8.31201H157.239V12.0254H166.985C167.459 12.0098 167.931 12.0897 168.373 12.2602C168.815 12.4307 169.219 12.6884 169.559 13.018C169.9 13.3476 170.171 13.7422 170.355 14.1786C170.54 14.6149 170.636 15.084 170.636 15.5579C170.636 16.0318 170.54 16.5008 170.355 16.9372C170.171 17.3735 169.9 17.7682 169.559 18.0977C169.219 18.4273 168.815 18.685 168.373 18.8555C167.931 19.026 167.459 19.1059 166.985 19.0903H160.949V13.705H157.232V27.553H160.949V22.8037H167.142C169.039 22.7667 170.847 21.9855 172.174 20.6283C173.501 19.2711 174.242 17.4467 174.237 15.5483Z"/>
            <path d="M24.5171 27.5263L24.5057 27.5529H28.5885L28.5771 27.5263L38.4375 5.42881L40.2466 6.27051L38.7727 0.291016L33.2388 2.99893L35.0746 3.85967L26.5471 22.9103L20.1182 8.30049H16.0583L9.62935 22.9103L4.88003 12.1053L0.762939 12.1015L7.60317 27.5263L7.58794 27.5529H11.6746L11.6593 27.5263L18.0882 12.9127L24.5171 27.5263Z"/>
          </g>
        </svg>
      </Logo>
    </div>
  </div>
</template>

<script>
import Logo from "./Logo";
import NavBar from "./NavBar";
import {mapGetters} from "vuex";

export default {
  name: "MainMenu",
  components: {NavBar, Logo},
  data() {
    return {
      isMenuOpen: false,
      isMenuActive: false,
      isLogoActive: false,
    }
  },
  computed: {
    ...mapGetters({
      isDesktop: 'isDesktop'
    })
  },
  mounted() {
    this.$anime({
      targets: '.MainMenu',
      width: '100%',
      easing: 'easeInOutQuad',
      duration: 1000,
      complete: () => {
        this.isMenuActive = !this.isMenuActive

        this.$anime({
          targets: '.MainMenu-Logo path',
          strokeDashoffset: [this.$anime.setDashoffset, 0],
          easing: 'easeInOutSine',
          duration: 1000,
          delay: (el, i) => i * 120,
          complete: () => {
            this.isLogoActive = !this.isLogoActive
          }
        })
      }
    })
  },
  methods: {
    onClickOutside(e) {
      if (!e.target.closest('.MainMenu-NavBar')) {
        // this.isMenuOpen = false
      }
    },
  }
}
</script>

<style lang="scss">
.MainMenu {
  overflow: hidden;
  width: 0;
  position: fixed;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 16px 0;
  background: var(--color-body);
  box-shadow: 0px 6px 8px rgba(82, 0, 107, 0.08);

  @include s-l {
    height: 76px;
    padding: 25px 0;
  }

  &--active {
    overflow: visible;
    opacity: 1;
  }

  &-Wrapper {
    opacity: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: opacity .5s ease-in-out;

    .MainMenu--active & {
      opacity: 1;
    }
  }

  &-Button {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    padding: 0;
    margin-left: 30px;
    z-index: 110;

    @include s-l {
      display: none;
    }

    > span {
      display: block;
      width: 100%;
      transform: translate3d(0, 0, 0);
      transition: all .3s ease-in-out;
      border-radius: 1px;
      margin-bottom: 6px;
      background-color: var(--color-mobile-link);
      height: 2px;
      pointer-events: none;

      &:last-child {
        margin-bottom: 0;
      }

      .MainMenu--opened & {
        &:nth-child(1) {
            transform: rotate(45deg) translate3d(3px, 8px, 0)
        }
        
        &:nth-child(2) {
            opacity: 0
        }
        
        &:nth-child(3) {
            transform: rotate(-45deg) translate3d(3px, -8px, 0)
        }    
      }
    }
    
  }

  &-NavBar {
    @include s-l {
      margin-left: 15%;
    }
  }

  &-Logo {
    position: relative;
    margin-right: 30px;
    z-index: 110;

    @include s-l {
      margin-right: 15%;
    }

    &--ready {
      path {
        transition: all .3s ease-in-out;
        stroke: var(--color-text-main2);
        fill: var(--color-text-main2);        
      }
    }

    .MainMenu--opened & {
      path {
        stroke: var(--color-text-main);
        fill: var(--color-text-main);
      }    
    }    
  }
}
</style>
