<template>
  <section
      class="Awards Wrapper"
      v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }"
  >
    <h3 class="Awards-Title">
      Why you should choose us?
    </h3>
    <div class="WhyWe">
      <div class="WhyWe-Square WhyWe-Square--1"></div>
      <div class="WhyWe-Square WhyWe-Square--4"></div>
      <div class="WhyWe-Square WhyWe-Square--3"></div>
      <div class="WhyWe-Square WhyWe-Square--2"></div>
      <div class="WhyWe-Square WhyWe-Square--5"></div>
      <div class="WhyWe-Square WhyWe-Square--6"></div>
      <div class="WhyWe-Square WhyWe-Square--7"></div>
      <div class="WhyWe-Square WhyWe-Square--8"></div>

      <div class="WhyWe-Box">
        <div class="Awards-Wrapper">
          <div class="AdvantagesList">
            <div class="WhyWe-MobileAnim">
              <div class="WhyWe-MobileSquare WhyWe-MobileSquare--1"></div>
              <div class="WhyWe-MobileSquare WhyWe-MobileSquare--2"></div>
              <div class="WhyWe-MobileSquare WhyWe-MobileSquare--3"></div>
              <div class="WhyWe-MobileSquare WhyWe-MobileSquare--4"></div>
              <div class="WhyWe-MobileSquare WhyWe-MobileSquare--5"></div>
              <div class="WhyWe-MobileSquare WhyWe-MobileSquare--6"></div>
              <div class="WhyWe-MobileSquare WhyWe-MobileSquare--7"></div>
              <div class="WhyWe-MobileSquare WhyWe-MobileSquare--8"></div>
            </div>            
            <div
                v-for="(advantage, $advantage) in advantagesList"
                :key="$advantage"
                class="Awards-AdvantageItem"
                :class="`Awards-AdvantageItem--${advantage.class}`"
            >
              <div class="Awards-AdvantageItemTitle">
                {{ advantage.title }}
              </div>
              <div class="Awards-AdvantageItemIndicator">
                {{ advantage.indicator }}
              </div>
              <div class="Awards-AdvantageItemText">
                {{ advantage.text }}
              </div>
            </div>
          </div>

          <div class="Awards-Awards">
            <div
                class="Awards-Top"
            >
              <div
                  class="Awards-AwardsTitle"
              >
                Our <br>rewards
              </div>
            </div>

            <img src="@/assets/images/appsflyer.svg" alt="icon" class="Awards-AppsFlyerLogo">

            <div
                class="Awards-List"
            >
              <div
                  v-for="(award, $award) in awardsList"
                  :key="'A'+$award"
                  class="Awards-AwardsItem"
                  :class="`Awards-AwardsItem--${award.class}`"
              >
                <img src="@/assets/images/appsflyer.svg" alt="icon" class="Awards-AwardsItemIcon">
                <div class="Awards-AwardsItemRank">
                  - {{ award.ranking }} -
                </div>
                <p
                    class="Awards-AwardsItemText"
                    v-html="award.text"
                ></p>
                <img src="@/assets/images/leaf.png" alt="image" class="Awards-AwardsItemImg">
              </div>
            </div>
          </div>

          <div class="AwardsRankings">
            <div class="AwardsRankings-Title">
              Our <br>rankings
            </div>

            <div class="AwardsRankings-List">
              <div
                class="AwardsRankings-ListItem"
                v-for="({img}, i) in awardsRankings"
                :key="i"
              >
                <img
                  :src="require(`@/assets/images/${img}`)"
                  alt=""
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Awards",
  data() {
    return {
      clientWidth: 1400,
      isShowAwards: false,
      isWaypointActive: false,
      intersectionOptions: {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0.25, 0.75] // [0.25, 0.75] if you want a 25% offset!
      }
    }
  },
  computed: {
    ...mapGetters({
      advantagesList: "getAdvantagesList",
      awardsList: "getAwardsList",
      awardsRankings: "getAwardsRankings",
      isMobile: 'isMobile',
    }),
    switchBgImage() {
      return this.isMobile ? 'advantages-bg-mobile.svg' : 'advantages-bg.png'
    }
  },
  mounted() {
    window.addEventListener('resize', this.resizeHandler)
    this.resizeHandler()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler)
  }, 
  methods: {
    resizeHandler() {
      if (document.body.clientWidth < 1400) {
        this.clientWidth = document.body.clientWidth
      } else {
        this.clientWidth = 1400
      }

      const cssText = `
        .WhyWe-Square,
        .WhyWe-MobileSquare {
            background-size: ${this.clientWidth}px auto
        }
      `

      const css = document.querySelector('#awards-css')

      if (!css) {
        const css = document.createElement('style')
        css.type = 'text/css'
        css.id = 'awards-css'
        css.appendChild(document.createTextNode(cssText))
        this.$el.appendChild(css)
      } else {
        css.innerHTML = ''
        css.appendChild(document.createTextNode(cssText))
      }
    },    
    onWaypoint ({ going }) {
      if (!this.isWaypointActive && going === this.$waypointMap.GOING_IN) {
        this.isWaypointActive = !this.isWaypointActive
        
        this
          .$anime
          .timeline()
          .add({
            targets: '.WhyWe-Square--7',
            width: '100%',
            easing: 'easeInOutQuad',
            duration: 1000
          })
          .add({
            targets: '.WhyWe-Square--8',
            width: '100%',
            easing: 'easeInOutQuad',
            duration: 1000
          }, '-=1000')          
          .add({
            targets: '.WhyWe-Square--6',
            width: '100%',
            easing: 'easeInOutQuad',
            duration: 1000
          }, '-=700')          
          .add({
            targets: '.WhyWe-Square--5',
            width: '100%',
            easing: 'easeInOutQuad',
            duration: 1000
          }, '-=1000')
          .add({
            targets: '.WhyWe-Square--4',
            width: '100%',
            easing: 'easeInOutQuad',
            duration: 1000
          }, '-=700')
          .add({
            targets: '.WhyWe-Square--3',
            width: '100%',
            easing: 'easeInOutQuad',
            duration: 1000
          }, '-=1000')
          .add({
            targets: '.WhyWe-Square--2',
            top: '16.39%',
            opacity: 1,
            easing: 'easeInOutQuad',
            duration: 700
          }, '-=700')
          .add({
            targets: '.WhyWe-Square--1',
            top: '4.65%',
            opacity: 1,
            easing: 'easeInOutQuad',
            duration: 1000,
            complete: () => {
              document.querySelector('.WhyWe-Box').classList.add('WhyWe-Box--ready')
            }
          }, '-=1000')
      
        this
          .$anime
          .timeline()
          .add({
            targets: '.WhyWe-MobileSquare--1',
            width: '100%',
            easing: 'easeInOutQuad',
            duration: 1000
          })
          .add({
            targets: '.WhyWe-MobileSquare--2',
            width: '100%',
            easing: 'easeInOutQuad',
            duration: 1000
          }, '-=1000')
          .add({
            targets: '.WhyWe-MobileSquare--3',
            width: '100%',
            easing: 'easeInOutQuad',
            duration: 1000
          }, '-=700')
          .add({
            targets: '.WhyWe-MobileSquare--4',
            width: '100%',
            easing: 'easeInOutQuad',
            duration: 1000
          }, '-=1000')
          .add({
            targets: '.WhyWe-MobileSquare--5',
            width: '100%',
            easing: 'easeInOutQuad',
            duration: 1000
          }, '-=700')
          .add({
            targets: '.WhyWe-MobileSquare--6',
            width: '100%',
            easing: 'easeInOutQuad',
            duration: 1000
          }, '-=1000')
          .add({
            targets: '.WhyWe-MobileSquare--7',
            width: '100%',
            easing: 'easeInOutQuad',
            duration: 1000
          }, '-=700')
          .add({
            targets: '.WhyWe-MobileSquare--8',
            width: '100%',
            easing: 'easeInOutQuad',
            duration: 1000,
            complete: () => {
              document.querySelector('.Awards-Title').classList.add('Awards-Title--ready')
              this.$anime.timeline().add({
                targets: '.Awards-AdvantageItem',
                opacity: 1,
                easing: 'easeInOutQuad',
                duration: 600,
                delay: function(el, i) { return i * 300 },
                complete: () => {
                  document.querySelector('.AwardsRankings').classList.add('AwardsRankings--ready')
                  document.querySelector('.Awards-Awards').classList.add('Awards-Awards--ready')
                }
              })              
            }
          }, '-=1000')
      }
    }
  }  
}
</script>

<style lang="scss">
.WhyWe {
  position: relative;

  @include s-s {  
    &::after {
      content: '';
      display: block;
      padding-bottom: percentage(1290 / 1400);
    }

    &-Square {
      position: absolute;
      height: 100%;
      top: 0;
      width: 0;
      background-repeat: no-repeat;
      background-size: contain;

      &--1 {
        opacity: 0;
        width: 100%;
        top: 230px;
        height: 425px;
        background-image: url('~@/assets/images/anim/why-sq-1.png');
      }

      &--2 {
        opacity: 0;
        width: 100%;
        top: 330px;
        height: 425px;
        background-image: url('~@/assets/images/anim/why-sq-2.png');
      }

      &--3 {
        left: 0;
        background-image: url('~@/assets/images/anim/why-sq-3.png');
      }

      &--4 {
        right: 0;
        background-position: 100% 0;
        background-image: url('~@/assets/images/anim/why-sq-4.png');
      }

      &--5 {
        right: 0;
        z-index: 5;
        background-image: url('~@/assets/images/anim/why-sq-5.png');
        background-position: 100% 0;
      }

      &--6 {
        left: 0;
        z-index: 6;
        background-image: url('~@/assets/images/anim/why-sq-6.png');
      }

      &--7 {
        left: 0;
        z-index: 8;
        background-image: url('~@/assets/images/anim/why-sq-7.png');
      }

      &--8 {
        right: 0;
        z-index: 7;
        background-image: url('~@/assets/images/anim/why-sq-8.png');
        background-position: 100% 0;
      }
    }

    &-Box {
      position: absolute;
      z-index: 99;
      width: 100%;
      height: 100%;
      transition: opacity .2s ease-in-out;    
      
      &--ready {
        opacity: 1;
      }
    }
  }

  &-Box {
    @include s-max-s {  
      position: relative;
      z-index: 10;
      padding-top: 29.93vw;
    }
  }

  &-MobileAnim {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-image: url('~@/assets/images/anim/why-mob-aq-0.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    
    &::after {
      content: '';
      display: block;
      padding-bottom: percentage(765 / 428);
    }

    @include s-s {  
      display: none;
    }
  }

  &-MobileSquare {
    position: absolute;
    height: 100%;
    top: 0;
    width: 0;
    background-repeat: no-repeat;
    background-size: contain;

    &--1 {
      z-index: 8;
      left: 0;
      background-image: url('~@/assets/images/anim/why-mob-aq-1.svg');
    }

    &--2 {
      z-index: 8;
      right: 0;
      background-position: 100% 0;
      background-image: url('~@/assets/images/anim/why-mob-aq-2.svg');
    }

    &--3 {
      z-index: 7;
      left: 0;
      background-image: url('~@/assets/images/anim/why-mob-aq-3.svg');
    }

    &--4 {
      z-index: 7;
      right: 0;
      background-position: 100% 0;
      background-image: url('~@/assets/images/anim/why-mob-aq-4.svg');
    }

    &--5 {
      z-index: 6;
      left: 0;
      background-image: url('~@/assets/images/anim/why-mob-aq-5.svg');
    }

    &--6 {
      z-index: 6;
      right: 0;
      background-position: 100% 0;
      background-image: url('~@/assets/images/anim/why-mob-aq-6.svg');
    }

    &--7 {
      z-index: 5;
      left: 0;
      background-image: url('~@/assets/images/anim/why-mob-aq-7.svg');
    }

    &--8 {
      z-index: 5;
      right: 0;
      background-position: 100% 0;
      background-image: url('~@/assets/images/anim/why-mob-aq-8.svg');
    }
  }  
}

.Awards {
  position: relative;
  padding: 0 0 6vw;

  @include s-s {
    padding: 3.7vw 0 .416vw;
  }

  @include s-xxl {
    padding: 52px 0;
  }

  &-Title {
    opacity: 0;
    position: absolute;
    top: 20.56vw;
    left: 0;
    z-index: 11;
    width: 100%;
    margin-bottom: 1.17%;
    font-size: 4.21vw;
    text-align: center;
    color: var(--color-text-main2);
    transition: opacity .2s ease-in-out;    
    
    @include s-s {
      font-weight: 300;
      position: static;
      margin-bottom: 2.24%;
      font-size: 3.4vw;
    }

    @include s-xxl {
      font-size: 48px;
    }

    &--ready {
      opacity: 1;
    }
  }

  &-Wrapper {
    @include s-s {
      position: relative;
      max-width: 1238px;
      width: 87.188vw;
      height: 100%;
      margin: 0 auto;
    }
    
    @include s-xxl {
      width: 100%;
    }
  }

  &-AdvantageItem {
    opacity: 0;
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15.5vw;
    text-align: center;
    color: var(--color-text-main);

    &:last-child {
      margin-bottom: 0;
    }

    @include s-s {
      margin-bottom: 0;
      position: absolute;

      &--years {
        top: 6%;
        left: 50%;
        transform: translate(-50%, 0);
        color: var(--color-text-main2);
      }
  
      &--work {
        top: 10%;
        left: 7%;
      }
  
      &--ftd {
        top: 10%;
        right: 7%;
      }
  
      &--team {
        top: 20%;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }

    @include s-max-s {
      &--years,
      &--work {
        color: var(--color-text-main2);
      }
    }
  }

  &-AdvantageItemTitle {
    margin-bottom: 2%;
    font-weight: 300;
    font-size: 4.67vw;
    line-height: 1.15;
    text-transform: uppercase;

    @include s-s {
      margin-bottom: 6%;
      font-size: 1.614vw;
    }

    @include s-xxl {
      font-size: 22px;
    }
  }

  &-AdvantageItemIndicator {
    font-weight: 300;
    font-size: 11.92vw;
    line-height: 1;
    text-transform: uppercase;

    @include s-s {
      font-size: 4.062vw;
    }

    @include s-xxl {
      font-size: 57px;
    }
  }

  &-AdvantageItemText {
    font-weight: 500;
    font-size: 4.21vw;
    line-height: 1.15;

    @include s-s {
      font-size: 1.25vw;
    }

    @include s-xxl {
      font-size: 17px;
    }
  }

  &-AppsFlyerLogo {
    display: block;
    width: 56.31vw;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 34px;

    @include s-s {
      display: none;
    }
  }

  &-AwardsTitle {
    font-weight: 500;
    font-size: 5.61vw;
    line-height: 1;
    color: var(--color-text-gold-dark);

    @include s-s {
      position: absolute;
      top: 27%;
      left: 0;
      font-size: 2.343vw;
    }

    @include s-xxl {
      font-size: 33px;
    }

    @include s-max-s {
      margin-bottom: 4.91vw;
      text-align: center;
      padding-top: 32px;

      br {
        display: none;
      }      
    }
  }

  &-Awards {
    opacity: 0;
    transition: opacity .2s ease-in-out;    
    
    &--ready {
      opacity: 1;
    }

    @include s-max-s {
      position: relative;
      margin-top: -20px;
      padding-right: 16px;
      padding-left: 16px;
      background-color: var(--color-bg-dark);
      padding-top: 20%;
      padding-bottom: 32px;
    
      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        margin-top: -1px;
        background-image: url('~@/assets/images/awards-arrow.svg');
        background-size: 100% auto;
        background-repeat: no-repeat;
        padding-top: percentage(60/428);
        z-index: 1;
      }      
    }
  }

  &-List {
    @include s-max-s {
      display: flex;
      flex-wrap: wrap;
      margin-right: -16px;
      margin-left: -16px;
    }
  }

  &-AwardsItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.15;
    text-align: center;
    color: var(--color-text-gold-light);
    
    @include s-max-s {
      width: 50%;
      flex: 0 0 auto;
      margin-bottom: 20px;
      padding-right: 16px;
      padding-left: 16px;

      &:nth-child(3n) {
        width: 100%;
      }
    }

    @include s-s {
      position: absolute;

      &--award1 {
        top: 35%;
        left: 0;
      }

      &--award2 {
        top: 41%;
        left: 21.5%;
      }

      &--award3 {
        top: 49%;
        left: 50%;
        transform: translate(-50%, 0);
      }

      &--award4 {
        top: 41%;
        right: 21.5%;
      }

      &--award5 {
        top: 35%;
        right: 0;
      }
    }
  }

  &-AwardsItemIcon {
    display: none;

    @include s-s {
      display: block;
      width: 12.917vw;
    }
    
    @include s-xxl {
      width: 183px;
    }
  }

  &-AwardsItemRank {
    font-weight: 300;
    font-size: 7.01vw;
    text-transform: uppercase;

    @include s-s {
      font-size: 2.135vw;
    }

    @include s-xxl {
      font-size: 30px;
    }
  }

  &-AwardsItemText {
    margin-bottom: 2.47%;
    font-weight: 500;
    font-size: 2.804vw;

    @include s-s {
      margin-bottom: 8.47%;
      font-size: .625vw;
    }

    @include s-xxl {
      font-size: 8px;
    }
  }

  &-AwardsItemImg {
    margin-top: auto;
    width: 9.157vw;

    @include s-s {
      margin-top: 0;
      width: 5.157vw;
    }

    @include s-xxl {
      width: 73px;
    }
  }
}

.AwardsRankings {
  opacity: 0;
  transition: opacity .2s ease-in-out;    
  
  &--ready {
    opacity: 1;
  }

  @include s-max-s {
    position: relative;
    background-color: #503573;
    margin-bottom: percentage(60/428);
    padding-top: percentage(60/428);
    padding-bottom: 32px;
  
    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      margin-top: -1px;
      width: 100%;
      background-image: url('~@/assets/images/rankings-arrow.svg');
      background-size: 100% auto;
      background-repeat: no-repeat;
      padding-top: percentage(60/428);
    }
  }

  &-Title {
    font-weight: 500;
    font-size: 5.61vw;
    line-height: 1;
    color: #B798E1;

    @include s-s {
      position: absolute;
      top: 59%;
      left: 0;
      font-size: 2.343vw;
    }

    @include s-xxl {
      font-size: 33px;
    }

    @include s-max-s {
      margin-bottom: 4.91vw;
      padding-top: 32px;
      text-align: center;

      br {
        display: none;
      }      
    }
  }

  &-List {
    @include s-max-s {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 9.35vw 5.14vw;
    }
  }

  &-ListItem {
    text-align: center;

    img {
      max-width: 36.45vw;
    }
    
    @include s-s {
      position: absolute;

      &:nth-child(1),
      &:nth-child(4) {
        top: 67%;

        img {
          max-width: 10.36vw;

          @include s-xxl {
            width: 145px;
          }
        }
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 72%;

        img {
          max-width: 12.86vw;

          @include s-xxl {
            width: 180px;
          }
        }
      }

      &:nth-child(1) {
        left: 9.07%;
      }

      &:nth-child(2) {
        left: 28.93%;
      }

      &:nth-child(3) {
        right: 28.93%;
      }

      &:nth-child(4) {
        right: 9.07%;
      }
    }
  }
}
</style>
