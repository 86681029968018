<template>
  <footer
    class="Footer"
    v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }"
  >
    <div class="Footer-In">
      <div class="Footer-Wrapper">
        <div class="Footer-Socials">
          <a v-for="(contact, $contact) in socials"
            :key="$contact"
            :href="contact.link"
            :title="contact.title"
            target="_blank"
            class="Footer-Social"
            :style="`background-image: url(${require(`@/assets/images/${contact.icon}`)})`"
          ></a>
        </div>
        <Logo class="Footer-Logo">
          <img :src="require(`@/assets/images/logo-footer.svg`)" alt="logo">
        </Logo>
        <div class="Footer-Copy">
          Copyright © 2022 wakeapp.com
        </div>
      </div>
      <div class="Footer-Docs">
        <a
            :href="`${publicPath}docs/WakeApp_Privacy_Policy_22.08.2022.pdf`"
            class="Footer-DocsItem"
            target="_blank"
            >Privacy Policy
        </a>
        <a
            :href="`${publicPath}docs/WakeApp_TERMS_OF_SERVICE_22.08.2022.pdf`"
            class="Footer-DocsItem"
            target="_blank"
            >Terms of Service
        </a>
      </div>
    </div>
    <svg id="linesvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1400 504">
        <g fill="none" fill-rule="evenodd" stroke="#fff" stroke-width=".5">
            <path d="M698.042,3340.15L-9,3554.35V3392l708-216,214.859,66.04L1701,3480.35V3644Z" transform="translate(0 -3037)"/>
            <path d="M698.042,3340.85L-9,3126.65V3289l708,216,214.859-66.04L1701,3200.65V3037Z" transform="translate(0 -3037)"/>
            <path d="M698.042,3503.15L-9,3717.35V3555l708-216,214.859,66.04L1701,3643.35V3807Z" transform="translate(0 -3037)"/>
            <path d="M698.042,3503.85L-9,3289.65V3452l708,216,214.859-66.04L1701,3363.65V3200Z" transform="translate(0 -3037)"/>
            <path d="M698.042,3665.15L-9,3879.35V3717l708-216,214.859,66.04L1701,3805.35V3969Z" transform="translate(0 -3037)"/>
            <path d="M698.042,3665.85L-9,3451.65V3614l708,216,214.859-66.04L1701,3525.65V3362Z" transform="translate(0 -3037)"/>
        </g>
    </svg>    
  </footer>
</template>

<script>
import Logo from "@/components/Logo";
import {mapGetters} from "vuex";

export default {
  name: "Footer",
  data() {
    return {
      isWaypointActive: false,
      intersectionOptions: {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0.75, 0.25] // [0.25, 0.75] if you want a 25% offset!
      },
      publicPath: process.env.BASE_URL
    }
  },
  components: {Logo},
  computed: {
    ...mapGetters({
      socials: "getSocials"
    })
  },
  methods: {
    onWaypoint ({ going }) {
      // going: in, out
      // direction: top, right, bottom, left
      if (!this.isWaypointActive && going === this.$waypointMap.GOING_IN) {
        this.isWaypointActive = !this.isWaypointActive

        const footer = document.querySelector('.Footer')

        footer.classList.add('start')

        this.$anime.timeline().add({
          targets: '.Footer #linesvg path',
          strokeDashoffset: [this.$anime.setDashoffset, 0],
          easing: 'easeInOutSine',
          duration: 1000,
          delay: (el, i) => { return i * 300 },
          complete: () => {
            footer.classList.add('ready')
          }
        })      
      }
    }     
  }
}
</script>

<style lang="scss">
.Footer {
  position: relative;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  z-index: 100;
  overflow: hidden;
  background: #221e26;
  transition: all .3s ease-in-out;
  min-height: 90px;

  @include s-m {
    min-height: 237px;
  }

  &-In {
    transition: all .3s ease-in-out;
    width: 100%;    
    background: url(~@/assets/images/anim/footer.svg) no-repeat;
    background-size: cover;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    padding: 8px 0;

    @include s-m {
      padding: 65px 0 0;
    }

    .ready & {
      opacity: 1;
      position: relative;
      min-height: 90px;
      
      @include s-m {
        min-height: 237px;
      }      
    }    
  }    

  &.ready {
    max-width: 100%;

    #linesvg {
      display: none;
    }
  } 

  &.start #linesvg {
      opacity: 1;
  }
  
  #linesvg {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 504px;
    opacity: 0;
    transition: opacity .3s ease-in-out;
  }

  &-Wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1035px;
    margin: 0 auto;
  }

  &-Socials {
    display: flex;
    align-items: center;
    margin-left: 15px;
    
    @include s-s {
      margin-left: 35px;
    }
    
    @include s-m {
      margin-left: 75px;
    }
  }

  &-Social {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    border: 1px solid #72598E;
    transition: opacity 0.25s ease;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 55% auto;

    @include s-m {
      width: 62px;
      height: 62px;      
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &-Logo {
    width: 144px;
    margin-right: 15px;

    @include s-s {
      margin-right: 35px;
    }

    @include s-m {
      margin-right: 75px;
    }
  }

  &-Copy {
    position: absolute;
    right: 15px;
    bottom: -14px;
    font-family: 'PTSans', sans-serif;
    font-weight: 400;
    font-size: 9px;
    line-height: 1.3;
    color: var(--color-text-copy);

    @include s-s {
      right: 50px;
    }

    @include s-m {
      right: 78px;
    }
  }

  &-Docs {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1035px;
    margin: 75px auto 0;
    padding: 7px 0;
    color: var(--color-text-copy);
    font-size: 10px;
    @media (max-width: $screen-m) {
        margin-top: 28px;
    }
  }
  &-DocsItem {
    margin: 0 20px;
    @media (max-width: $screen-xs) {
        margin: 0 7px;
    }
  }
}
</style>
