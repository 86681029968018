<template>
  <nav
    class="NavBar"
    :class="{'NavBar--opened': isOpened}"
  >
    <ul class="NavBar-List">
      <li
        class="NavBar-Item"
        v-for="(link, $link) in getNav"
        :key="$link"
      >
        <router-link
          class="NavBar-Link"
          @click.native="$emit('open-menu')"
          :to="link.to"      
        >
          {{ link.name }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "NavBar",
  props: {
    isOpened: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['getNav'])
  }
}
</script>

<style lang="scss">
.NavBar {
  @include s-max-l {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    transform: translateY(-100%);
    transition: transform .3s ease-in-out;
    will-change: transform;    
    width: 100%;
    min-height: 100vh;
    padding: 60px 40px;
    background-color: #c4c4c4;
    background-image: url(~@/assets/images/anim/header-bg-1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0;

    &#{&}--opened {
      transform: translateY(0);
    }
  }

  &-List {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    padding-top: 30px;

    @include s-l {
      display: flex;
      align-items: center;
      border-top: none;
      padding-top: 0;
    }
  }
  
  &-Item {
    margin-bottom: 1.5em;

    @include s-l {
      margin-bottom: 0;
      margin-right: 7.14vw;

      @include s-xxl {
        margin-right: 100px;
      }
    }
  }

  &-Link {
    white-space: nowrap;
    font-weight: 500;
    font-size: 20px;
    line-height: 1;
    color: var(--color-text-main);

    @include s-l {
      color: var(--color-main);
      font-size: 18px;
      transition: .3s;
      
      &:hover {
        color: var(--color-bav-hover);
      }
    }
  }

  .router-link-active {
    color: var(--color-bav-hover);
  }
}
</style>
