<template>
  <section
    class="Description"
    v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }"
  >
    <div class="Wrapper">
      <div
          class="Description-Cards"
          :class="{'Description-Cards--padding': showText && isMobile}"
      >
        <div
            v-for="(info, $info) in description"
            :key="$info"
            class="Description-Card"
            style="opacity:0; transform: scale(0.9)"
        >
          <img
              :src="require(`@/assets/images/${info.img}`)"
              alt="icon"
              class="Description-CardIcon"
              width="160"
              height="132"
          >
          <h4
              class="Description-CardTitle"
              v-html="info.title"
          ></h4>
          <p
              class="Description-CardText"
              v-html="info.text"
          ></p>
          <div class="Description-CardBottom Description-CardBottom--margintop">
            <router-link
                v-show="!showText || isDesktop"
                :to="info.link"
                class="Btn Btn--white Description-CardBtn"
            >
              More
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Description",
  data() {
    return {
      showText: false,
      isWaypointActive: false,
      intersectionOptions: {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0.75, 0.25] // [0.25, 0.75] if you want a 25% offset!
      },      
    }
  },
  computed: {
    ...mapGetters({
      description: 'getDescription',
      isMobile: 'isMobile',
      isDesktop: 'isDesktop'
    })
  },
  methods: {
    onClickOutside(e) {
      if (!e.target.closest('.Description')) this.showText = false;
    },
    onWaypoint ({ going }) {
      // going: in, out
      // direction: top, right, bottom, left
      if (!this.isWaypointActive && going === this.$waypointMap.GOING_IN) {
        this.isWaypointActive = !this.isWaypointActive
        
        this.$anime.timeline().add({
          targets: '.Description-Card',
          opacity: 1,
          scale: 1,
          easing: 'easeInOutQuad',
          duration: 600,
          delay: function(el, i) { return i * 300 }
        })
      }
    }     
  }
}
</script>

<style lang="scss">
.Description {
  background-color: var(--color-body);

  @include s-l {
    padding-top: 100px;
    padding-bottom: 80px;
  }

  &-Cards {
    @include s-l {
      display: grid;
      grid-template-columns: repeat(2, 1fr);    
      gap: 0 50px;
    }

    @include s-xxl {
      gap: 0 205px;
    }
  }

  &-Card {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 32px 0;
    text-align: center;
    border-top: 1px solid rgba(82, 0, 107, 0.15);

    @include s-l {
      border-top: none;
    }

    &:first-child {
      border-top: none;
    }
  }

  &-CardIcon {
    max-width: 90px;

    @include s-l {
      margin-bottom: 20px;
      max-width: 100%;
    }
  }

  &-CardTitle {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 22px;
    text-align: center;
    text-transform: uppercase;
    color: var(--color-text-main2);

    @include s-l {
      font-size: 33px;
    }
  }

  &-CardText {
    padding: 0 31px 0 35px;
    font-size: 16px;
    
    @include s-l {
      padding: 0 2.6em;
      font-size: 18px;
    }
  }

  &-CardBtn {
    z-index: 3;
    padding: .48em 3.58em .44em;

    .mobile &,
    .landscape &,
    .portrait & {
      padding: 0.32em 1.71em;
    }
  }

  &-CardBottom {
    margin-top: auto;
    padding-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include s-l {
      padding-top: 70px;
    }
  }

  &-CardItem {
    position: relative;
    padding-left: 2.33%;
    display: inline-block;
    text-align: center;

    &:before {
      position: absolute;
      content: "";
      width: .15em;
      height: .15em;
      background-color: var(--color-bg-main1);
      border-radius: 50%;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }
  }
}
</style>
