import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        platform: 'desktop',
        mobilePlatform: null,
        windowSize: [0, 0],
        nav: [
            {to: "user-acquisition", name: "User acquisition"},
            {to: "in-app-network", name: "In-app Network"},
            {to: "contacts", name: "Contacts"},
        ],
        partnersIcons: [
            "wat-14.png",
            "wat-15.png",
            "wat-13.png",
            "our-sources/05-fb.png",
            "our-sources/07-asa.png",
            "our-sources/04-twi.png",
            "our-sources/09-snap.png",
            "our-sources/10-xia.png",
            "our-sources/08-tiktok.png",
            "our-sources/12-applovin.png",
            "our-sources/13-adcol.png",
            "our-sources/11-google-ads.png",
            "our-sources/16-shareit.png",
            "our-sources/17-dv360.png",
            "our-sources/14-digit-tur.png",
            "our-sources/21-oceanen.png",
            "our-sources/22-bigoad.png",
            "our-sources/19-mobya.png",
            "our-sources/26-snaptu.png",
            "our-sources/27-simeji.png",
            "our-sources/24-chartb.png",
            "our-sources/30-baidu.png",
            "our-sources/33-wake.png",
            "our-sources/28-appnex.png",
            "wat-16.png",
            "wat-25.png",
        ],
        ourSources: [
            "our-sources/01-unity.png",
            // "our-sources/02-is.png",
            "our-sources/03-vung.png",
            "our-sources/04-twi.png",
            "our-sources/05-fb.png",
            "our-sources/07-asa.png",
            "our-sources/08-tiktok.png",
            "our-sources/09-snap.png",
            "our-sources/10-xia.png",
            "our-sources/11-google-ads.png",
            "our-sources/12-applovin.png",
            "our-sources/13-adcol.png",
            "our-sources/14-digit-tur.png",
            "our-sources/16-shareit.png",
            "our-sources/17-dv360.png",
            "our-sources/19-mobya.png",
            // "our-sources/20-mint.png",
            "our-sources/21-oceanen.png",
            "our-sources/22-bigoad.png",
            // "our-sources/23-molo.png",
            "our-sources/24-chartb.png",
            // "our-sources/25-hua.png",
            "our-sources/26-snaptu.png",
            "our-sources/27-simeji.png",
            "our-sources/28-appnex.png",
            "our-sources/30-baidu.png",
            "our-sources/33-wake.png"
        ],
        description: [
            {
                img: "description-star.svg",
                title: "WakeApp In-App Network",
                text: "Works with the largest advertising platforms and publishers. We have generated over 1 million leads and secured over 500 million registrations in a year.",
                link: "in-app-network",
                classPadding: "Btn--padding"
            },
            {
                iconPadding: '2.3%',
                iconPaddingMobile: '4.3%',
                img: "description-play.svg",
                title: "User acquisition",
                text: "We have 150 UA managers in-house and our own optimization technology for getting high quality users and ROI.",
                link: "user-acquisition",
                classImg: "Img--size"
            },
        ],
        advantagesList: [
            {
                title: "",
                indicator: "8",
                text: "years on the market",
                class: "years"
            },
            {
                title: "we work",
                indicator: "24/7",
                text: "for results",
                class: "work"
            },
            {
                title: "More than",
                indicator: "500 000",
                text: "FTD per year",
                class: "ftd"
            },
            {
                title: "a team of",
                indicator: "300+",
                text: "professionals, who love mobile",
                class: "team"
            },
        ],
        awardsList: [
            {
                ranking: "2",
                text: "Growth Index in the region of<br> Eastern Europe in the non-<br>gaming Android category",
                class: "award1"
            },
            {
                ranking: "3",
                text: "Growth Index in the region of<br> Western Europe in the non-<br>gaming Android category",
                class: "award2"
            },
            {
                ranking: "4",
                text: "Retention Index (Volume<br> Ranking) in the region of Eastern<br> Europe in the category of Life & <br>Culture for iOS",
                class: "award3"
            },
            {
                ranking: "7",
                text: "Retention Index (Power<br> Ranking) in the region of Eastern<br> Europe in the category of Life &<br> Culture for iOS",
                class: "award4"
            },
            {
                ranking: "29",
                text: "IAP Index (Power Ranking) in<br> the non-gaming category for<br> Android OS",
                class: "award5"
            },
        ],
        awardsRankings: [
            {
                img: "awards-rankings-1.png"
            },
            {
                img: "awards-rankings-2.png"
            },
            {
                img: "awards-rankings-3.png"
            },
            {
                img: "awards-rankings-4.png"
            }
        ],
        socials: [
            {icon: "instagram.svg", title: "instagram.com/wakeapp_group", link: "https://instagram.com/wakeapp_group?utm_medium=copy_link"},
            {icon: "linkedin.svg", title: "linkedin.com/company/3818300", link: "https://www.linkedin.com/company/3818300"},
            {icon: "mail.svg", title: "info@wakeapp.com", link: "mailto:info@wakeapp.com"},
        ],
        contacts: {
            welcome: 
            {
                title: "welcome@wakeapp.com",
                link: "mailto:welcome@wakeapp.com"
            },            
            employment: [
                {title: "hr@wakeapp.com", link: "mailto:hr@wakeapp.com"},
            ],
            allRequirements: [
                {title: "info@wakeapp.com", link: "mailto:info@wakeapp.com"},
                {title: "linkedin.com/wakeapp", link: "https://www.linkedin.com/company/3818300"},
            ]
        },
        ourClients: [
            {
                logo: "client-disney.png",
                logos: [],
                info: {
                    flags: [],
                    flagsText: '',
                    title: "Disney",
                    info: [
                        {rank: "480%", text: "Organic growth"},
                        {rank: "167K", text: "Organic users"},
                        {rank: "$0.25", text: "Per&nbsp;organic download"},
                    ],
                    stars: "",
                    review: []
                }
            },
            {
                logo: "client-aliexpress.png",
                logos: [],
                info: {
                    flags: [
                        "flag-us.png",
                        "flag-de.png",
                        "flag-gb.png",
                        "flag-in.png",
                        "flag-br.png",
                    ],
                    flagsText: "+25 other countries",
                    title: "AliExpress",
                    info: [
                        {rank: "3.9M", text: "Organic users"},
                        {rank: "5.5%", text: "CR&nbsp;to purchase"},
                    ],
                    stars: "",
                    review: []
                }
            },
            {
                logo: "client-octafx.png",
                logos: [],
                info: {
                    flags: [
                        "flag-in.png",
                        "flag-pk.png",
                        "flag-my.png",
                        "flag-mc.png",
                        "flag-za.png",
                        "flag-ng.png",
                        "flag-th.png",
                        "flag-ph.png",
                        "flag-sg.png",
                    ],
                    flagsText: "",
                    title: "OctaFX",
                    info: [
                        {rank: "372%", text: "Growth rate"},
                        {rank: "3%", text: "CR to&nbsp;target action"},
                    ],
                    stars: "",
                    review: []
                }
            },
            {
                logo: "client-binance.png",
                logos: [],
                info: {
                    flags: [
                        "flag-br.png",
                        "flag-ph.png",
                        "flag-tr.png",
                        "flag-ar.png",
                        "flag-es.png",
                        "flag-sg.png",
                        "flag-us.png",
                        "flag-cy.png",
                        "flag-kr.png",
                    ],
                    flagsText: "",
                    title: "Binance",
                    info: [
                        {rank: "40%", text: "Growth rate"},
                        {rank: "12%", text: "CR to&nbsp;target action"}
                    ],
                    stars: "",
                    review: []
                }
            },
            {
                logo: "client-binomo.png",
                logos: [],
                info: {
                    flags: [
                        "flag-in.png",
                    ],
                    flagsText: "",
                    title: "Binomo",
                    info: [
                        {rank: "220%", text: "Growth rate"},
                        {rank: "12%", text: "CR to&nbsp;target action"}
                    ],
                    stars: "",
                    review: []
                }
            },
            {
                logo: "client-expertoption.png",
                logos: [],
                info: {
                    flags: [
                        "flag-my.png",
                        "flag-th.png",
                        "flag-in.png",
                        "flag-ph.png",
                    ],
                    flagsText: "",
                    title: "ExpertOption",
                    info: [
                        {rank: "110%", text: "Growth rate"},
                        {rank: "12%", text: "CR to&nbsp;target action"}
                    ],
                    stars: "",
                    review: []
                }
            },
        ],
        cases: [
            {
                logo: "william-logos.png",
                logos: [
                    'case-logo-1.png',
                    'case-logo-2.png',
                    'case-logo-3.png',
                ],
                info: {
                    flags: [
                        "gb.png",
                    ],
                    title: "WILLIAM HILL",
                    info: [
                        {rank: "2+", text: "years of solid <br>budget delivery"},
                        {rank: "> 33%", text: "CR from registration<br> to deposit"},
                    ],
                    stars: "",
                    review: [
                        ""
                    ]
                }
            },
            {
                logo: "888-logos.png",
                logos: [
                    'case-logo-4.png',
                    'case-logo-5.png',
                    'case-logo-6.png',
                    'case-logo-7.png',
                ],
                info: {
                    flags: [
                        "gb.png",
                        "us.png",
                        "spain.png",
                        "sweden.png",
                        "portugal.png",
                    ],
                    title: "888.COM",
                    info: [
                        {rank: "3+", text: "years multiple <br>GEO acquisition"},
                        {rank: "> 44%", text: "CR from registration<br> to deposit"},
                    ],
                    stars: "",
                    review: [
                        ""
                    ]
                }
            },
            {
                logo: "bet365-logos.png",
                logos: [
                    'case-logo-8.png',
                ],
                info: {
                    flags: [
                        "gb.png",
                    ],
                    title: "bet365",
                    info: [
                        {rank: "> 150%", text: "ROI"},
                        {rank: "> 37%", text: "CR from registration<br> to deposit"},
                    ],
                    stars: "",
                    review: [
                        ""
                    ]
                }
            },
            {
                logo: "entain-logos.png",
                logos: [
                    'case-logo-9.png',
                    'case-logo-10.png',
                    'case-logo-11.png',
                    'case-logo-12.png',
                    'case-logo-13.png',
                    'case-logo-14.png',
                    'case-logo-15.png',
                    'case-logo-16.png',
                    'case-logo-17.png',
                ],
                info: {
                    flags: [
                        "gb.png",
                        "ireland.png",
                    ],
                    title: "ENTAIN",
                    info: [
                        {rank: "3+", text: "years of solid <br>budget delivery"},
                        {rank: "> 40%", text: "CR from registration<br> to deposit"},
                    ],
                    stars: "",
                    review: [
                        ""
                    ]
                }
            },
            {   
                logo: "mansion-logos.png",
                logos: [
                    'case-logo-18.png',
                    'case-logo-19.png',
                    'case-logo-20.png',
                ],
                info: {
                    flags: [
                        "gb.png",
                    ],
                    title: "Mansion",
                    info: [
                        {rank: "> 200%", text: "ROI"},
                        {rank: "> 30%", text: "CR from registration <br>to deposit"},
                    ],
                    stars: "stars.svg",
                    review: [
                        "\"WakeApp worked with us to put together a Mobile In-App Display strategy with a focus not only on FTD growth, but player quality. The execution that followed was excellent and we were delighted with the campaign results.\""
                    ]
                }
            },
        ],
        advantages: [
            {
                icon: "automation.png",
                title: "automation",
                text: "Traffic optimization technologies allow you to get the maximum return on investment",
                class: "automation",
            },
            {
                icon: "star-small.png",
                title: "Creativity",
                text: "The creative department knows what approach is needed to attract and “hook” your target audience",
                class: "creativity",
            },
            {
                icon: "shield.png",
                title: "Anti-Fraud",
                text: "Our own anti-fraud system protects our customers from fraud and low-quality traffic",
                class: "anti-fraud",
            },
            {
                icon: "activity.png",
                title: "Quality and Volumes",
                text: "Due to the accumulated expertise, we generate large volumes of high-quality traffic in a short time",
                class: "quality",
            },
            {
                icon: "game.png",
                title: "Client Service",
                text: "A transparent approach to work, weekly reports, and a dedicated manager allow us to provide the highest level of service",
                class: "client",
            },
        ],
        clientsJourney: [
            {
                text: "The user sees an ad for the&nbsp;app in&nbsp;one of&nbsp;our sources"
            },
            {
                text: "Clicks on the&nbsp;ad and <b>goes to the marketplace page</b>"
            },
            {
                text: "Downloads the&nbsp;application"
            },
            {
                text: "Opens the application and&nbsp;<b>registers</b>"
            }
        ],
        clients: [
            {
                left: "wat-13.png",
                right: [
                    "wat-14.png", 
                    "wat-15.png"
                ]
            },
            {
                left: "our-sources/04-twi.png",
                right: [
                    "our-sources/05-fb.png", 
                    "our-sources/07-asa.png"
                ]
            },
            {
                left: "our-sources/08-tiktok.png",
                right: [
                    "our-sources/09-snap.png", 
                    "our-sources/10-xia.png"
                ]
            },
            {
                left: "our-sources/11-google-ads.png",
                right: [
                    "our-sources/12-applovin.png", 
                    "our-sources/13-adcol.png"
                ]
            },
            {
                left: "our-sources/14-digit-tur.png",
                right: [
                    "our-sources/16-shareit.png", 
                    "our-sources/17-dv360.png"
                ]
            },
            {
                left: "our-sources/19-mobya.png",
                right: [
                    "our-sources/21-oceanen.png", 
                    "our-sources/22-bigoad.png"
                ]
            },
            {
                left: "our-sources/24-chartb.png",
                right: [
                    "our-sources/26-snaptu.png", 
                    "our-sources/27-simeji.png"
                ]
            },
            {
                left: "our-sources/28-appnex.png",
                right: [
                    "our-sources/30-baidu.png", 
                    "our-sources/33-wake.png"
                ]
            },
            {
                right: [
                    "wat-16.png", 
                    "wat-25.png"
                ]
            }
        ]
    },
    mutations: {
        setPlatform(state, platform) {
            state.platform = platform
        },
        setMobilePlatform(state, mobilePlatform) {
            state.mobilePlatform = mobilePlatform;
        },
    },
    actions: {},
    getters: {
        platform: state => state.platform,
        mobilePlatform: state => {
            return state.mobilePlatform
        },
        isMobile: state => state.platform === 'mobile',
        isTablet: state => state.platform === 'tablet',
        isDesktop: state => state.platform === 'desktop',
        isLandscape: state => state.windowSize[0] > state.windowSize[1],
        isPortrait: (_, getters) => !getters.isLandscape,
        getNav(state) {
            return state.nav
        },
        getOurSources(state) {
            return state.ourSources
        },
        getPartnersIcons(state) {
            return state.partnersIcons
        },
        getDescription(state) {
            return state.description
        },
        getAdvantagesList(state) {
            return state.advantagesList
        },
        getAwardsList(state) {
            return state.awardsList
        },
        getAwardsRankings(state) {
            return state.awardsRankings
        },
        getSocials(state) {
            return state.socials
        },
        getContacts(state) {
            return state.contacts
        },
        getOurClients(state) {
            return state.ourClients
        },
        getCases(state) {
            return state.cases
        },
        getAdvantages(state) {
            return state.advantages
        },
        getClientsJourney(state) {
            return state.clientsJourney
        },
        getClients(state) {
            return state.clients
        }
    },
    modules: {}
})
