<template>
  <div>
    <MainMenu />

    <div class="Header" :class="`Header--${$route.name}`">
      <div class="Header-TopBack"></div>

      <div class="Header-In Wrapper">
        <div class="Header-CenterSquare"></div>
        <div class="Header-BottomSquare"></div>
        <div class="Header-Square"></div>

        <HeaderLogo :page="$route.name" />

        <template v-if="$route.name === 'home'">
          <div class="Header-MainDesc">
            your guide <br />to the world of mobile
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderLogo from '@/components/HeaderLogo'
import MainMenu from '@/components/MainMenu'

export default {
  name: 'Header',
  data() {
    return {
      clientWidth: 1400,
    }
  },
  components: {
    HeaderLogo,
    MainMenu,
  },
  mounted() {
    window.addEventListener('resize', this.resizeHandler)
    this.resizeHandler()
    this.initAnim()
    this.scrollBg()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  methods: {
    resizeHandler() {
      if (document.body.clientWidth < 1400) {
        this.clientWidth = document.body.clientWidth
      } else {
        this.clientWidth = 1400
      }

      const cssText = `
        .Header-Square {
            background-size: ${this.clientWidth}px auto
        }
        .Header-BottomSquare {
            background-size: ${this.clientWidth / 2}px auto
        }
      `

      const headerCSS = document.querySelector('#header-css')

      if (!headerCSS) {
        const css = document.createElement('style')
        css.type = 'text/css'
        css.id = 'header-css'
        css.appendChild(document.createTextNode(cssText))
        this.$el.appendChild(css)
      } else {
        headerCSS.innerHTML = ''
        headerCSS.appendChild(document.createTextNode(cssText))
      }
    },
    initAnim() {
      this.$anime({
        targets: '.Header-TopBack',
        width: '100%',
        easing: 'easeInOutQuad',
        duration: 1000,
      })

      this.$anime
        .timeline()
        .add({
          targets: '.Header-BottomSquare',
          width: '50%',
          easing: 'easeInOutQuad',
          duration: 650,
        })
        .add(
          {
            targets: '.Header-Square',
            width: '100%',
            easing: 'easeInOutQuad',
            complete: () => {
              document.querySelector('.Header').classList.add('Header--ready')

              this.$anime({
                targets: '.Header-Logo path',
                strokeDashoffset: [this.$anime.setDashoffset, 0],
                easing: 'easeInOutSine',
                duration: 1000,
                delay: (el, i) => {
                  return i * 0
                },
                complete: () => {
                  document
                    .querySelector('.Header-Logo')
                    .classList.add('Header-Logo--ready')

                  if (this.$route.name === 'home') {
                    document
                      .querySelector('.Header-MainDesc')
                      .classList.add('Header-MainDesc--ready')
                    document
                      .querySelector('.About')
                      .classList.add('About--ready')
                  }

                  if (this.$route.name === 'user-acquisition') {
                    document
                      .querySelector('.AboutBayer')
                      .classList.add('AboutBayer--ready')
                  }

                  if (this.$route.name === 'cases') {
                    document
                      .querySelector('.CasesContent')
                      .classList.add('CasesContent--ready')
                  }

                  if (this.$route.name === 'our-clients') {
                    document
                      .querySelector('.OurClients')
                      .classList.add('OurClients--ready')
                  }

                  if (this.$route.name === 'contacts') {
                    document
                      .querySelector('.Contacts-Form')
                      .classList.add('Contacts-Form--ready')
                  }

                  if (this.$route.name === 'in-app-network') {
                    document
                      .querySelector('.InAppNetwork')
                      .classList.add('InAppNetwork--ready')
                  }
                },
              })
            },
          },
          '-=650'
        )
        .add(
          {
            targets: '.Header-CenterSquare',
            opacity: 1,
            translateY: ['-100%', 0],
            easing: 'easeInOutQuad',
            duration: 500,
          },
          '-=500'
        )
    },
    scrollBg() {
      if (this.clientWidth < 1400 || this.$route.name !== 'home') return

      const el = document.querySelector('.Header-Square')

      window.addEventListener('scroll', () => {
        if (window.scrollY < 300) {
          el.style.backgroundPosition = `0 ${-window.scrollY / 5}px`
        }
      })
    },
  }
}
</script>

<style lang="scss">
.Header {
  $header-width: 1400;
  $header-height: 624;

  position: relative;
  z-index: 1;
  width: 100%;
  padding-top: 75px;

  @include s-l {
    padding-top: 85px;
  }

  &-TopBack {
    position: absolute;
    left: 0;
    top: 60px;
    width: 0;
    height: 20vw;
    background: linear-gradient(
      267.54deg,
      #b19ace 33.23%,
      rgba(238, 238, 241, 0.15) 100%
    );
    pointer-events: none;
    will-change: width;

    @include s-l {
      top: 76px;
    }

    @include s-xxl {
      height: 209px;
    }
  }

  &-In {
    position: relative;
    padding-right: 0;
    padding-left: 0;
    overflow: hidden;

    &::after {
      content: '';
      display: block;
      padding-top: percentage($header-height / $header-width);
    }
  }

  &-Logo {
    opacity: 0;
    position: absolute;
    z-index: 1000;
    width: 100%;
    transition: opacity 0.5s ease-in-out;

    .Header--ready & {
      opacity: 1;
    }

    &--ready {
      .path-1,
      .path-2 {
        transition-property: stroke-width, fill;
        transition-timing-function: ease-in-out;
        transition-duration: 0.5s;
        stroke-width: 0;
      }

      .path-1 {
        fill: #fe5555;
      }

      .path-2 {
        fill: #ededf0;
      }

      .path-3 {
        fill: #fff;
      }
    }

    &--home {
      top: 0;
      left: percentage(207 / $header-width);
      max-width: percentage(593 / $header-width);
    }

    &--cases {
      top: percentage(130 / $header-height);
      left: 50%;
      transform: translateX(-50%);
      max-width: percentage(522 / $header-width);
    }

    &--our-clients {
      top: percentage(82 / $header-height);
      left: 50%;
      transform: translateX(-50%);
      max-width: percentage(724 / $header-width);
    }

    &--user-acquisition {
      top: percentage(130 / $header-height);
      left: 50%;
      transform: translateX(-50%);
      max-width: percentage(810 / $header-width);
    }

    &--contacts {
      top: percentage(200 / $header-height);
      left: 50%;
      transform: translateX(-50%);
      max-width: percentage(913 / $header-width);
    }

    &--in-app-network {
      top: percentage(100 / $header-height);
      left: 50%;
      transform: translateX(-50%);
      max-width: percentage(910 / $header-width);
    }

    svg {
      max-width: 100%;
      height: auto;
    }
  }

  &-Square {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-repeat: no-repeat;
    background-size: contain;
    transform: translateZ(0);
    z-index: 998;
    will-change: width;

    .Header--home & {
      background-image: url('~@/assets/images/anim/head-1.png');

      @include s-xxl {
        clip-path: polygon(
          0px 199.25px,
          700px 0.917px,
          1400px 199.25px,
          1400px 613.417px,
          0px 199.25px
        );
        background-image: url('~@/assets/images/anim/header-bg-1.jpg');
      }
    }

    .Header--cases & {
      background-image: url('~@/assets/images/anim/head-2.png');
    }

    .Header--user-acquisition & {
      background-image: url('~@/assets/images/anim/head-3.png');
    }

    .Header--contacts & {
      background-image: url('~@/assets/images/anim/head-4.png');
    }

    .Header--in-app-network & {
      background-image: url('~@/assets/images/anim/head-5.png');
    }

    .Header--our-clients & {
      background-image: url('~@/assets/images/anim/head-6.png');
    }
  }

  &-BottomSquare {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-image: url('~@/assets/images/anim/red-head-square2.png');
    background-repeat: no-repeat;
    background-size: contain;
    will-change: width;

    .Header--cases &,
    .Header--contacts & {
      background-image: url('~@/assets/images/anim/blue-head-square2.png');
    }

    .Header--our-clients &,
    .Header--in-app-network &,
    .Header--user-acquisition & {
      background-image: url('~@/assets/images/anim/dblue-head-square2.png');
    }
  }

  &-CenterSquare {
    opacity: 0;
    position: absolute;
    left: percentage(329 / $header-width);
    top: percentage(372 / $header-height);
    transform: translateY(-100%);
    width: 100%;
    max-width: percentage(749 / $header-width);
    background-image: url('~@/assets/images/anim/black-head-square.svg');
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden;
    z-index: -1;
    will-change: opacity;

    &::after {
      content: '';
      display: block;
      padding-bottom: percentage(165 / 749);
    }

    .Header--in-app-network & {
      @include s-m {
        display: none;
      }
    }
  }

  &-MainDesc {
    opacity: 0;
    position: absolute;
    top: percentage(248 / $header-height);
    left: percentage(699 / $header-width);
    z-index: 1000;
    transition: opacity 0.5s ease-in-out;
    padding: 0.35vw 0.71vw;
    background: #ff5252;
    color: #fff;
    font-size: 3.35vw;
    font-weight: 300;
    line-height: 1;

    @include s-xxl {
      top: 248px;
      left: 699px;
      padding: 5px 10px;
      font-size: 47px;
    }

    &--ready {
      opacity: 1;
    }
  }
}
</style>
