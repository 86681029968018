<template>
  <section class="About">
    <div class="Wrapper">
      <h2 class="About-Title">
        WakeApp —
      </h2>
      <div class="About-Subtitle">
        the best performing mobile <span class="Colored">marketing agency</span>
      </div>
      <Partners
          :partners-title="'Our Clients and Partners'"
          :slider-info="getPartnersIcons"
          class="About-Partners"
      />
      <div class="About-Text Colored">
        8 years of expertise help us to dive into the project and develop and implement
        a comprehensive strategy to attract new paying users
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import Partners from "@/components/Partners";

export default {
  name: "About",
  components: {Partners},
  computed: {
    ...mapGetters(['getPartnersIcons'])
  }
}
</script>

<style lang="scss">
.About {
  position: relative;
  transition: opacity 0.5s ease-in-out;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  &:not(&--ready) {
    opacity: 0;
  }

  &:before {
    position: absolute;
    z-index: 3;
    content: "";
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 18.23vw solid transparent;
    border-right: 18.23vw solid transparent;
    border-top: 4.43vw solid var(--color-body1);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  &:after {
    position: absolute;
    z-index: 4;
    content: "";
    top: 100%;
    left: 50%;
    transform: translate(-50%, -4px);
    width: 0;
    height: 0;
    border-left: 18.23vw solid transparent;
    border-right: 18.23vw solid transparent;
    border-top: 4.43vw solid var(--color-body1);
  }

  &-Title {
    font-size: 25px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    letter-spacing: 0.02em;
    color: var(--color-text-main2);

    @include s-l {
      font-size: 3.6vw;
    }

    @include s-xxl {
      font-size: 51px;
    }
  }

  &-Subtitle {
    margin-bottom: 4%;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    color: var(--color-text-main2);

    @include s-l {
      font-weight: 300;
      font-size: 2.88vw;
    }

    @include s-xxl {
      font-size: 40px;
    }
  }

  &-Text {
    max-width: 780px;
    margin: 0 auto;
    font-weight: 400;
    font-size: 22px;
    line-height: 1.50;
    text-align: center;
    padding-bottom: 40px;

    @include s-l {
      font-weight: 300;
    }
  }

  &-Partners {
    margin-bottom: 35px;

    @include s-l {
      margin-bottom: 66px;
    }
  }
}
</style>
