<template>
  <div>
    <input
      :name="name"
      :id="name"
      type="checkbox"
      :checked="checked"
      class="Checkbox"
      :class="{ 'Checkbox--error': v.$dirty && v.required }"
      @change="$emit('input', $event.target.checked)"
    />
    <label
      :for="name"
      >{{ label }}</label
    >
  </div>
</template>

<script>
export default {
  name: "BaseCheckbox",
  props: {
    name: {
      type: [String],
      required: true
    },
    label: {
      type: [String, Number],
      required: false,
      default: ""
    },
    checked: {
      type: Boolean,
      required: false,
      default: false
    },
    v: {
      type: [Object, Boolean],
      required: false,
      default: false
    }
  },
};
</script>

<style lang="scss">
.Checkbox {
    margin-right: 10px;
  &--error {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
