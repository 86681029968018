<template>
  <form
      class="Form"
      novalidate
      @submit.prevent="onSubmit"
  >
    <div class="Form-Form">
      <div class="Form-Title">
        Write to us
        <img src="@/assets/images/envelop.svg" alt="envelop">
      </div>
      <div class="Form-Inputs">
        <div class="Form-InputsTop">
          <BaseInput
            type="text"
            v-model.trim="form.contacts.value"
            class="Form-Input"
            :placeholder="form.contacts.label"
            :label="form.contacts.label"
            :v="$v.form.contacts.value"
            :name="form.contacts.name"
          />
        </div>
      </div>
      <BaseTextarea
          v-model="form.message.value"
          class="Form-Textarea"
          :label="form.message.label"
          :v="$v.form.message.value"
      />
      <BaseCheckbox
        v-model="form.policyCheckbox.value"
        :name="'policyCheckbox'"
        :label="form.policyCheckbox.label"
        :v="$v.form.policyCheckbox.value"
      />
      <BaseCheckbox
        v-model="form.newslettersCheckbox.value"
        :name="'newslettersCheckbox'"
        :label="form.newslettersCheckbox.label"
      />
      <button class="Btn Btn--red Form-Btn">
        Send
      </button>
    </div>
    <div class="Form-Contacts">
      <a
        class="Form-Contact"
        :href="contacts.welcome.link"
      >
        {{ contacts.welcome.title }}
      </a>

      <vue-recaptcha
          @verify="onVerify"
          sitekey="6LfLccUgAAAAAOTKTIcsO54P1rrj4tSzbP2UUwjH"
          class="Form-Captcha"
      ></vue-recaptcha>

    </div>
  </form>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required} from "vuelidate/lib/validators";
import axios from "axios";
import {mapGetters} from "vuex";
import {VueRecaptcha} from 'vue-recaptcha';
import {showModal} from "@/assets/utils/utils";

export default {
  name: "Form",
  components: {VueRecaptcha},
  mixins: [validationMixin],
  data() {
    return {
      form: {
        contacts: {
          name: 'contacts',
          value: '',
          label: 'Your contact (email, skype, telegram, etc)*',
        },
        message: {
          value: '',
          label: 'Your message*',
        },
        policyCheckbox: {
          value: false,
          label: 'I consent to the processing of my personal data for marketing purposes in accordance with the privacy policy',
        },
        newslettersCheckbox: {
          value: false,
          label: 'I agree to receive newsletters from WakeApp',
        },
        robot: false
      },
    }
  },
  computed: {
    ...mapGetters({
      contacts: "getContacts"
    })
  },
  validations: {
    form: {
      contacts: {
        value: {
          required,
        },
      },
      message: {
        value: {
          required,
        },
      },
      policyCheckbox: {
        value: {
          required,
        },
      },
    }
  },
  methods: {
    showModal,
    onVerify(response) {
      if (response) this.form.robot = true;
    },
    resetForm() {
      this.form.contacts.value = ''
      this.form.message.value = ''
      this.form.newslettersCheckbox.value = false
      this.form.policyCheckbox.value = false
    },
    async submitToCustomerApi() {
        const {contacts, message, newslettersCheckbox} = this.form;
        const body = {
            data: {
              contacts: contacts.value,
              message: message.value,
              receiveNewsletterse: String(newslettersCheckbox.value)
            }
        } 

        const options = {
            headers: {
                "content-type": "application/json",
                "Authorization": `Basic ${process.env.VUE_APP_CUSTOMER_API_TOKEN}=`
            }
        };

       await axios.post('https://track-eu.customer.io/api/v1/forms/wakeapp/submit', body, options)
    },
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$error /*&& this.form.robot*/) {
        return;
      }
      const params = new URLSearchParams();
        params.append('contacts', this.form.contacts.value);
        params.append('message', this.form.message.value);

        await axios.post(
            '/mail.php',
            params,
            {
              headers: {
                'content-type': 'application/x-www-form-urlencoded'
              }
            }
        ).then(() => {
            this.submitToCustomerApi();
        }).then(() => {
            this.showModal()
            this.resetForm()
            this.$nextTick(() => {
                this.$v.$reset()
            })
        }).catch( e => console.log(e) )
    }
  }
}
</script>

<style lang="scss">
.Form {
  position: relative;
  z-index: 2;

  @include s-m {
    display: flex;
    max-width: 1035px;
    width: 100%;
    margin: 0 auto;
  }

  &-Form {
    flex: 1 1 auto;
    padding: 30px 20px;
    color: var(--color-text-main);
    background-color: var(--color-bg-main1-light);

    @include s-m {
      padding: 29px 63px 26px;
    }
  }

  &-Inputs {
    margin-bottom: 31px;
  }

  &-InputsTop {
    margin-bottom: 36px;

    @include s-m {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &-Input {
    width: 100%;

    & + & {
      margin-top: 36px;

      @include s-m {
        margin-top: 0;
        margin-left: 30px;
      }
    }
  }

  &-Textarea {
    margin-bottom: 37px;
  }

  &-Btn {
    position: relative;
    padding: 9px 68px 9px;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 20px;
  }

  &-Title {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    font-size: 18px;
    text-transform: uppercase;

    img {
      width: 26px;
      margin: 0 0 3px 7px;
    }
  }

  &-Contacts {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    font-size: 18px;
    text-transform: lowercase;
    background-color: var(--color-body);
    
    @include s-m {
      width: 320px;
      padding: 29px 25px 29px 28px;
    }
  }

  &-Contact {
    position: relative;
    z-index: 2;
    margin-bottom: 3px;
    color: var(--color-text-main1-light);

    &:before {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--color-text-main1-light);
      opacity: 0;
      transition: opacity .3s;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }

  &-Captcha {
    width: 100%;
    order: -1;
    margin-bottom: 12px;

    @include s-m {
      position: absolute;
      right: 5%;
      bottom: 5%;
      margin-bottom: 0;
    }

    > div {
      margin-right: auto;
      margin-left: auto;
    }
  }
}
</style>
