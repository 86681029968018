<template>
  <section
      class="FormSection Wrapper"
      :class="[
          {'FormSection--bg-color': $route.name === 'user-acquisition'}
      ]"
      v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }"
  >
    <div class="FormSection-In">
      <h3 class="FormSection-Title">
        Do you have questions?
      </h3>

      <Form
        class="FormSection-Form"
        :class="{'FormSection-Form--active': showForm}"
      />

      <div class="FormSection-RedLine"></div>
    </div>
  </section>
</template>

<script>
import Form from "@/components/Form";

export default {
  name: "FormSection",
  components: {Form},
  data() {
    return {
      showForm: false,
      isWaypointsActive: false,
      intersectionOptions: {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0.25, 0.75] // [0.25, 0.75] if you want a 25% offset!
      }
    }
  },
  methods: {
    onWaypoint ({ going }) {
      // going: in, out
      // direction: top, right, bottom, left
      if (!this.isWaypointsActive && going === this.$waypointMap.GOING_IN) {
        this.isWaypointsActive = !this.isWaypointsActive

        this
          .$anime
          .timeline()
          .add({
            targets: '.FormSection-RedLine',
            width: '100%',
            easing: 'easeInOutQuad',
            duration: 1000,
            complete: () => {
              this.showForm = !this.showForm
            }
          }) 
      }
    }    
  }
}
</script>

<style lang="scss">
.FormSection {
  position: relative;
  overflow: hidden;
  padding-right: 0;
  padding-left: 0;

  &-RedLine {
    position: absolute;
    left: 0;
    top: 190px;
    width: 0;
    height: 100%;
    background: url(~@/assets/images/anim/red-line.png) no-repeat;
  }

  &-In {
    padding: 35px 0;

    @include s-l {
      padding: 70px 0;
    }

    .FormSection--bg-color & {
      background-color: var(--color-body);
    }
  }

  &-Title {
    margin-bottom: 14px;
    font-weight: 300;
    font-size: 22px;
    line-height: 150%;
    text-align: center;
    color: var(--color-text-main2);
    
    @include s-l {
      margin-bottom: 42px;
      font-size: 48px;
    }
  }

  &-Form {
    transition: opacity .5s ease-in-out;

    &:not(&--active) {
      opacity: 0;
    }    
  }
}
</style>
