import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

const Cases = () => import('../views/Cases.vue')
const OurClients = () => import('../views/OurClients.vue')
const Contacts = () => import('../views/Contacts.vue')
const UserAcquisition = () => import('../views/UserAcquisition.vue')
const InAppNetwork = () => import('../views/InAppNetwork.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/cases',
    name: 'cases',
    component: Cases,
  },
  {
    path: '/our-clients',
    name: 'our-clients',
    component: OurClients,
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: Contacts,
  },
  {
    path: '/user-acquisition',
    name: 'user-acquisition',
    component: UserAcquisition,
  },
  {
    path: '/in-app-network',
    name: 'in-app-network',
    component: InAppNetwork,
  },
  {
    path: '*',
    redirect() {
      return '/'
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }
    return {
      x: 0,
      y: 0,
    }
  },
  routes,
})

export default router
