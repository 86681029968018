<template>
  <div class="SliderBox">
    <swiper
      :options="options"
      class="Slider"
    >
      <swiper-slide
        v-for="(icon, $icon) in sliderInfo"
        :key="$icon"
        class="Slider-Slide"
      >
        <img
          :src="require(`@/assets/images/${icon}`)"
          alt="partners icons"
          :class="{
            'Slider-Slide--hover': $route.name !== 'user-acquisition'
          }"
        >
      </swiper-slide>
    </swiper>

    <img src="@/assets/images/chevron-left.svg" alt="" class="Slider-Btn Prev" slot="button-prev">
    <img src="@/assets/images/chevron-right.svg" alt="" class="Slider-Btn Next" slot="button-next">
  </div>
</template>

<script>
import {Navigation} from "swiper";
import {mapGetters} from "vuex";

export default {
  name: "PartnersSlider",
  props: {
    sliderInfo: {
      type: [Object, Array],
      default: () => {
      },
    },
  },
  data() {
    return {
      options: {
        modules: [Navigation],
        slidesPerView: 'auto',
        slidesPerGroup: 5,
        speed: 700,
        navigation: {
          nextEl: '.Next',
          prevEl: '.Prev'
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile'
    }),
  },
}
</script>

<style lang="scss">

.SliderBox {
  position: relative;
  margin-right: -10px;
  margin-left: -10px;
  
  @include s-l {
    min-width: 1px;
    margin-left: 2%;
    padding-right: 64px;
    padding-left: 64px;
  }
}

.Slider {
  margin: 0 auto;
  overflow: hidden;
  cursor: ew-resize;

  &-Slide {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 4vw !important;
    width: 56px !important;
    // height: 6vw !important;
    height: 85px !important;
    // margin-right: 2vw;
    margin-right: 28px;

    img {
      border-radius: 20%;
    }
  }

  &-Slide--hover {
    &:hover {
      transform: scale(1.24);
    }
  }

  &-Btn {
    display: none;

    @include s-l {
      display: block;
      position: absolute;
      width: 2.7%;
      top: 50%;
      transform: translate(0, -50%);
      z-index: 100;
      cursor: pointer;
    }
  }
}

.Prev {
  left: 0;
}

.Next {
  right: 0;
}
</style>
