<template>
  <div class="Partners">
    <div class="Partners-Title">
      {{ partnersTitle }}
    </div>
    <PartnersSlider :slider-info="sliderInfo"/>
  </div>
</template>

<script>
import PartnersSlider from "@/components/PartnersSlider";

export default {
  name: "Partners",
  components: {
    PartnersSlider
  },
  props: {
    partnersTitle: {
      type: String,
      default: ""
    },
    sliderInfo: {
      type: [Object, Array],
      default: () => {
      },
    },
  },
}
</script>

<style lang="scss">
.Partners {
  border-top: 1px solid rgba(82, 0, 107, 0.15);
  padding-top: 38px;
  
  @include s-s {
    padding: 0;
    border: none;
  }

  @include s-l {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-Title {
    margin-left: 2%;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.50;
    text-transform: uppercase;
    color: var(--color-text-main2);
    white-space: nowrap;
    text-align: center;

    @include s-s {
      font-size: 2.6vw;
    }
    @include s-l {
      flex: 0 0 auto;
      font-size: 1.4vw;
      text-align: left;
    }

    @include s-xxl {
      font-size: 19px;
    }
  }
}
</style>
