<template>
  <div class="Modal">
    <img
        src="@/assets/images/close.svg"
        alt="close modal"
        class="Modal-Close"
        @click="$modal.hide('Modal')"
    >
    <div class="Modal-Content">
      Thank you!<br>
      We will contact you soon.
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal"
}
</script>

<style lang="scss">
.Modal {
  position: relative;
  padding: 70px 40px 40px;
  border-radius: 5%;

  &-Close {
    position: absolute;
    width: 34px;
    top: 20px;
    right: 20px;
  }

  &-Content {
    font-size: 40px;
    line-height: 1.5;
    text-align: center;
    color: var(--color-text-main2);
  }
}
</style>