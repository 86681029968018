<template>
  <router-link
      to="/"
      class="Logo"
  >
    <slot/>
  </router-link>
</template>

<script>

export default {
  name: "Logo",
  data() {
    return {}
  },
}
</script>

<style lang="scss">
.Logo {
  width: 112px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include s-l {
    width: 10.48%;
  }

  img {
    width: 100%;
  }
}
</style>